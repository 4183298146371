.forecast {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Futura-Medium, Futura, sans-serif;
  font-size: 15px;
}

.forecast-summary {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summary-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.summary-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 2s;
  transition:
    opacity 150ms linear,
    transform 250ms ease-out;
}
.summary-exit {
  opacity: 1;
  transform: translateY(0);
}
.summary-exit-active {
  opacity: 0;
  transform: translateY(-40px);
  transition-delay: 2s;
  transition:
    opacity 150ms linear,
    transform 250ms ease-in;
}

.ranges {
  height: 50vh;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(50vh);
  scroll-snap-align: end;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.ranges > * {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.forecast-hourly {
  flex: 1;
}
.forecast-daily {
  scroll-snap-align: start;
}

.data-value {
  display: inline-block;
  position: relative;
  margin-left: -0.1em;
}
.data-value.windSpeed,
.data-value.pressureSurfaceLevel,
.data-value.uvIndex {
  margin-left: 0;
}
.data-value:after {
  position: absolute;
  left: 100%;
  opacity: 0.5;
}
.temperature:not(:empty):after,
.temperatureApparent:not(:empty):after,
.dewPoint:not(:empty):after {
  content: '\00b0';
  font-size: 0.94em;
  margin-left: -0.05em;
}
.humidity:not(:empty):after,
.precipitationProbability:not(:empty):after {
  content: '%';
  font-size: 0.5em;
  margin-left: 0.1em;
  line-height: 1.5rem;
}

/* handheld landscape */
@media screen and (max-height: 414px) {
  .ranges,
  .ranges > * {
    height: 100vh;
  }
}
@media screen and (min-width: 768px) and (min-height: 768px) {
  .ranges,
  .ranges > * {
    height: 60vh;
  }
}
